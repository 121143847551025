<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-container class="px-0">
            <v-row justify="center">
                <v-col cols="12" sm="10">
                    <h1 class="page-header font-weight-light mb-5">About GMRS</h1>

                    <h2 class="headline font-weight-light mb-1">Overview</h2>

                    <p>
                        The General Mobile Radio Service, or GMRS, is a personal two-way radio service created by the
                        Federal Communications Commission (FCC) in the United States. Transmitting on GMRS frequencies
                        requires a license from the FCC, however no knowledge test is required to obtain a license. A
                        license covers you and your immediate family members and is valid for 10 years. Currently, the
                        fee is $35 for the 10-year term, which is only $3.50 per year!
                    </p>

                    <h3 class="headline font-weight-light mb-1">License</h3>

                    <p>A license from the FCC is required to transmit on GMRS frequencies. There is no knowledge test to
                        obtain the license, simply pay the required fee and a callsign will be issued to you. A license
                        covers you as well as your immediate family members (i.e. spouse, children, parents,
                        grandparents, grandchildren, aunts, uncles, and in-laws).</p>

                    <div class="text-center mb-5">
                        <InArticleAdsense
                            data-ad-client="ca-pub-2391363374702677"
                            data-ad-slot="4051888556"
                            v-if="!user.subscribed"
                        >
                        </InArticleAdsense>
                    </div>

                    <h3 class="headline font-weight-light mb-1">Cost</h3>

                    <p>
                        A GMRS license is valid for 10 years and costs $35, or only $3.50 per year. When you consider that
                        this covers your immediate family as well, this makes GMRS an economical way to stay in touch
                        with your family.
                    </p>

                    <h3 class="headline font-weight-light mb-1">GMRS Rules</h3>

                    <p>
                        The FCC has developed a set of rules that govern the Personal Radio Services, which includes
                        FRS, GMRS, MURS, and CB Radio to name a few. These rules fall under Part 95 of Title 47, Chapter
                        1, Subchapter D of the Code of Federal Regulations. Part 95 is further broken up into Subparts
                        A - M. Subpart A covers all of the Personal Radio Services, whereas Subpart E covers GMRS
                        specifically.
                    </p>

                    <p>
                        These rules must be followed at all times in order to make GMRS a safe and friendly environment,
                        and to prevent harmful interference to other stations.
                    </p>

                    <ul>
                        <li>
                            <a href="https://www.ecfr.gov/current/title-47/chapter-I/subchapter-D/part-95/subpart-A"
                               target="_blank">Part 95 Subpart A: General Rules for the Personal Radio Services</a>
                            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                        </li>
                        <li>
                            <a href="https://www.ecfr.gov/current/title-47/chapter-I/subchapter-D/part-95/subpart-E"
                               target="_blank">Part 95 Subpart E: General Mobile Radio Service</a>
                            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                        </li>
                    </ul>

                    <p></p>

                    <h3 class="headline font-weight-light mb-1">Frequency Chart</h3>

                    <v-simple-table class="elevation-1 mb-2">
                        <thead>
                        <tr class="rfnavy">
                            <th class="subtitle-1 white--text">Channel</th>
                            <th class="subtitle-1 white--text">Frequency (MHz)</th>
                            <th class="subtitle-1 white--text">Max Power</th>
                            <th class="subtitle-1 white--text">Bandwidth</th>
                            <th class="subtitle-1 white--text text-center">Repeaters Allowed</th>
                            <th class="subtitle-1 white--text">Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="n in 7" :key="'interstitial-' + n">
                            <td>
                                <v-chip label color="info">{{ n }}</v-chip>
                            </td>
                            <td>{{ 462.5625 + ((n - 1) * 0.025) }}</td>
                            <td>5W ERP</td>
                            <td>20 kHz</td>
                            <td class="text-center">
                                <v-icon color="error">mdi-close</v-icon>
                            </td>
                            <td></td>
                        </tr>
                        <tr v-for="n in 7" :key="'frs-' + n">
                            <td>
                                <v-chip label dark color="purple">{{ 7 + n }}</v-chip>
                            </td>
                            <td>{{ 467.5625 + ((n - 1) * 0.025) }}</td>
                            <td>0.5W</td>
                            <td>12.5 kHz</td>
                            <td class="text-center">
                                <v-icon color="error">mdi-close</v-icon>
                            </td>
                            <td>Hand-held Radios Only</td>
                        </tr>
                        <tr v-for="n in 8" :key="'main-' + n">
                            <td>
                                <v-chip label dark color="green">{{ 14 + n }}</v-chip>
                            </td>
                            <td>{{ (462.550 + ((n - 1) * 0.025)).toFixed(3) }}</td>
                            <td>50W</td>
                            <td>20 kHz</td>
                            <td class="text-center">
                                <v-icon color="success">mdi-check</v-icon>
                            </td>
                            <td>Repeater Input +5 MHz ({{ (467.550 + ((n - 1) * 0.025)).toFixed(3) }})</td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                    <p class="subtitle"><strong>Note:</strong> FRS may operate license-free on all channels except
                        repeater
                        inputs with 12.5 kHz bandwidth and 2W ERP power on all channels except 8-14.</p>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        props: ['user'],

        components: {AdBTWR},

        mounted() {
            useTitle('Help: About GMRS - myGMRS.com');
        }
    }
</script>
